export default {

    init(){
        this.accountButton();
        this.openModal();
        this.mouseLeave();
        //this.frontendLoginSilent();

    },

    accountButton(){
        var $this = this;
        $('.role_account_btn').click(function() {
            location.href = '/admin';
        });
        $('.account_btn').click(function() {
            location.href = '/account/info'
        });
        //$('#login_form_btn').click( $this.frontendLoginSilent());
    },

    openModal(){
        $('#login-btn').click(function() {
            $('#login-modal').attr('data-modal-state', 'open');
        });
        $('#already_acc_btn').click(function () {
            $('#pickup_modal').attr('data-modal-state', 'closed');
            $('#login-modal').attr('data-modal-state', 'open')
        });
        $('.schedule_pickup_modal').click(function() {
            $('#pickup_modal').attr('data-modal-state', 'open');
        });
    },

    mouseLeave(){
        if($('#user_id').val()==''){
            var $this = this;
            let m_pos_X = 0;
            let m_pos_Y = 0;
            let popupCounter = 0;

            document.addEventListener("mousemove", function (e) {
                m_pos_X = e.clientX;
                m_pos_Y = e.clientY;
            });

            document.addEventListener("mouseleave", function (e) {
                setTimeout(
                    function(){
                        if (m_pos_Y < 100) {
                            var user_exit_flag = $this.getCookie('user_exit_flag');
                            if (user_exit_flag !== "1") {
                                document.getElementById('modal-leaving').setAttribute('data-modal-state', 'open')
                            }
                            popupCounter++;
                            var date = new Date();
                            date.setTime(date.getTime() + (1 * 60 * 60 * 1000));
                            var expires = "expires=" + date.toUTCString();
                            document.cookie = "user_exit_flag=" + 1 + "; " + expires;
                        }
                    }, 5000);
            });
        }
    },

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },

    frontendLoginSilent(){
            $("#login_form_btn").attr('disabled', 'true');

            $.post('/login', {
                'email': $("#email").val(),
                'password': $("#password").val()
            }, 'json').done(function ( response ) {

                window.location.href = '/login';

                $("#login_form_btn").attr('disabled', '');

            }).fail(function( response ) {

                if (response.responseJSON.errors && response.responseJSON.errors.email && response.responseJSON.errors.email[0]) {

                    document.getElementById("error-message-login").innerHTML = response.responseJSON.errors.email[0];

                } else if (response.responseJSON.errors && response.responseJSON.errors.username && response.responseJSON.errors.username[0]) {

                    document.getElementById("error-message-login").innerHTML = response.responseJSON.errors.username[0];

                } else {

                    document.getElementById("error-message-login").innerHTML = "Oops. Something went wrong on our side. Ply try again later.";
                }

                $("#login_form_btn").attr('disabled', '');

            });
    },
}
