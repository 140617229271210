
require('./bootstrap');
require('./order/order-old');
require('../../liox/js/jquery.maskedinput141');


import faq from './faq';
import order from './order/order';
import timecode from './order/timecode';
import home_page from './home-page';


jQuery(function($) {
    $.mask.definitions['9'] = '';
    $.mask.definitions['d'] = '[0-9]';
});

$(document).ready(function () {
    faq.init();
    order.init();
    timecode.init();
    home_page.init();

    $('.modal-close-btn').click(function() {
        parent = this.parentElement;
        parent.parentElement.classList.add('hide');
    });

    $('.schedule_pickup').click(function () {
        location.href ="/order-page?service="+ $('#service').val();
    });
    //$('[name="zipcode"]').mask("10999");
    //$('[name="phone"]').mask("999-999-9999");

    $('.service-radio').click(function() {
        $('#service').val(this.value);
    });

    $('.main-header-mobile-btn').click(function() {
        if (this.classList.contains('open')) {
            this.classList.remove('open');
            this.classList.add('close');
            $('.main-header-mobile-menu').addClass('close');
            $('.main-header-mobile-menu').removeClass('open');
            $('.mobile-menu-bg').removeClass('open');
        } else {
            this.classList.remove('close');
            this.classList.add('open');
            $('.main-header-mobile-menu').addClass('open');
            $('.main-header-mobile-menu').removeClass('close');
            $('.mobile-menu-bg').addClass('open');
        }
    });
    $('.login-btn').click(function(){
        $('#login-modal').attr('data-modal-state', 'open');
    });


    var strQuery = window.location.search;
    var strSearch   = strQuery.substr(1),
        strPattern  = /([^=]+)=([^&]+)&?/ig,
        arrMatch    = strPattern.exec(strSearch),
        objRes      = {};
    while (arrMatch != null) {
        objRes[arrMatch[1]] = arrMatch[2];
        arrMatch = strPattern.exec(strSearch);
    }
    if(objRes['success']=='true'){
        $('#succes-pickup-modal').removeClass('hide');
        window.history.pushState({},'Laundry Luna - Laundry Service','/','');
    }
    $('#login_form').submit(frontendLoginSilent);

    $('#wlpeUserRegisterForm').click(function() {
        return onStore();
    });

    function checkZip() {
        console.log('test');
    };
    function frontendLoginSilent(){
        $("#login_form_btn").attr('disabled', 'true');

        $.post('/login', {
            'email': $("#email").val(),
            'password': $("#password").val()
        }, 'json').done(function ( response ) {

            window.location.href = '/login';

            $("#login_form_btn").attr('disabled', '');

        }).fail(function( response ) {

            if (response.responseJSON.errors && (response.responseJSON.errors.email && response.responseJSON.errors.email[0])) {

                document.getElementById("error-message-login").innerHTML = response.responseJSON.errors.email[0];

            } else if (response.responseJSON.errors && response.responseJSON.errors.username && response.responseJSON.errors.username[0]) {

                document.getElementById("error-message-login").innerHTML = response.responseJSON.errors.username[0];

            } else if(response.responseJSON.errors && response.responseJSON.errors.password && response.responseJSON.errors.password[0]){

                document.getElementById("error-message-login").innerHTML = "Unfortunately your email or password don't match our records.";

            } else {

                document.getElementById("error-message-login").innerHTML = "Oops. Something went wrong on our side. Ply try again later.";
            }

            $("#login_form_btn").removeAttr('disabled');
        });
    };

    var ccErrorNo = 0;
    var ccErrors = new Array ()

    function onStore() {

        let cardNum = $('#card_number').val();
        let cardExp = $('#exp_date').val();

        if (cardNum != '' || cardExp != '') {

            let cardType = checkCreditCard(cardNum);

            if (cardType === false) {
                document.getElementById('card_number').style.color = 'red';
                document.getElementById('card_number').style.borderColor = 'red';

                document.getElementById('card-type').value = '';
                document.getElementById('card-type').style.color = 'red';
                document.getElementById('card-type').style.borderColor = 'red';
            } else {
                document.getElementById('card_number').style.color = '';
                document.getElementById('card_number').style.borderColor = '';

                document.getElementById('card-type').value = cardType;
                document.getElementById('card-type').style.color = '';
                document.getElementById('card-type').style.borderColor = '';
            }

            if (getExpDate() == false) {
                $('#exp_date').css('border-color', 'red');
                return false;
            } else {
                $('#exp_date').css('border-color', '');
            }

            return true;
        }

        return true;
    }
    function getExpDate() {
        let exp_date_split = $('#exp_date').val().split('/');
        if (exp_date_split.length !== 2) {
            return false;
        }
        if (parseInt(exp_date_split[0]) <= 0 || parseInt(exp_date_split[0]) > 12) {
            return false;
        }
        let currentFullYear = (new Date()).getFullYear();
        if (parseInt(exp_date_split[1]) < (currentFullYear - 2000)) {
            return false;
        }
        let currentMonth = (new Date()).getMonth();
        if (parseInt(currentMonth) > parseInt(exp_date_split[0]) && parseInt(exp_date_split[1]) <= (currentFullYear - 2000)) {
            return false;
        }

        return [exp_date_split[0], exp_date_split[1]];
    }

    ccErrors [0] = "Unknown card type";
    ccErrors [1] = "No card number provided";
    ccErrors [2] = "Credit card number is in invalid format";
    ccErrors [3] = "Credit card number is invalid";
    ccErrors [4] = "Credit card number has an inappropriate number of digits";

    function checkCreditCard (cardnumber) {
        var cardname = "none";
        var cardprefix=0;
        cardprefix = cardnumber.substring(0, 1); if(cardprefix == "4"){cardname="Visa";}
        cardprefix = cardnumber.substring(0, 2); if(cardprefix == "51" || cardprefix == "52" || cardprefix == "53" || cardprefix == "54" || cardprefix == "55"){cardname="MasterCard";}
        cardprefix = cardnumber.substring(0, 2); if(cardprefix == "34" || cardprefix == "37"){cardname="Amex";}
        cardprefix = cardnumber.substring(0, 4); if(cardprefix == "6011"){cardname="Discover";}
        cardprefix = cardnumber.substring(0, 3); if(cardprefix == "622" || cardprefix == "37"){cardname="Discover";}
        cardprefix = cardnumber.substring(0, 2); if(cardprefix == "64" || cardprefix == "65"){cardname="Discover";}

        // Array to hold the permitted card characteristics
        var cards = new Array();

        // Define the cards we support. You may add addtional card types as follows.

        //  Name:         As in the selection box of the form - must be same as user's
        //  Length:       List of possible valid lengths of the card number for the card
        //  prefixes:     List of possible prefixes for the card
        //  checkdigit:   Boolean to say whether there is a check digit

        cards [0] = {name: "Visa",
            length: "13,16",
            prefixes: "4",
            checkdigit: true};
        cards [1] = {name: "MasterCard",
            length: "16",
            prefixes: "51,52,53,54,55",
            checkdigit: true};
        cards [2] = {name: "Amex",
            length: "15",
            prefixes: "34,37",
            checkdigit: true};
        cards [3] = {name: "Discover",
            length: "16",
            prefixes: "6011,622,64,65",
            checkdigit: true};

        // Establish card type
        var cardType = -1;
        for (var i=0; i<cards.length; i++) {

            // See if it is this card (ignoring the case of the string)
            if (cardname.toLowerCase () == cards[i].name.toLowerCase()) {
                cardType = i;
                break;
            }
        }

        // If card type not found, report an error
        if (cardType == -1) {
            ccErrorNo = 0;
            return false;
        }

        // Ensure that the user has provided a credit card number
        if (cardnumber.length == 0)  {
            ccErrorNo = 1;
            return false;
        }

        // Now remove any spaces from the credit card number
        cardnumber = cardnumber.replace (/\s/g, "");

        // Check that the number is numeric
        var cardNo = cardnumber
        var cardexp = /^[0-9]{13,19}$/;
        if (!cardexp.exec(cardNo))  {
            ccErrorNo = 2;
            return false;
        }

        // Now check the modulus 10 check digit - if required
        if (cards[cardType].checkdigit) {
            var checksum = 0;                                  // running checksum total
            var mychar = "";                                   // next char to process
            var j = 1;                                         // takes value of 1 or 2

            // Process each digit one by one starting at the right
            var calc;
            for (i = cardNo.length - 1; i >= 0; i--) {

                // Extract the next digit and multiply by 1 or 2 on alternative digits.
                calc = Number(cardNo.charAt(i)) * j;

                // If the result is in two digits add 1 to the checksum total
                if (calc > 9) {
                    checksum = checksum + 1;
                    calc = calc - 10;
                }

                // Add the units element to the checksum total
                checksum = checksum + calc;

                // Switch the value of j
                if (j ==1) {j = 2} else {j = 1};
            }

            // All done - if checksum is divisible by 10, it is a valid modulus 10.
            // If not, report an error.
            if (checksum % 10 != 0)  {
                ccErrorNo = 3;
                return false;
            }
        }

        // The following are the card-specific checks we undertake.
        var LengthValid = false;
        var PrefixValid = false;
        var undefined;

        // We use these for holding the valid lengths and prefixes of a card type
        var prefix = new Array ();
        var lengths = new Array ();

        // Load an array with the valid prefixes for this card
        prefix = cards[cardType].prefixes.split(",");

        // Now see if any of them match what we have in the card number
        for (i=0; i<prefix.length; i++) {
            var exp = new RegExp ("^" + prefix[i]);
            if (exp.test (cardNo)) PrefixValid = true;
        }

        // If it isn't a valid prefix there's no point at looking at the length
        if (!PrefixValid) {
            ccErrorNo = 3;
            return false;
        }

        // See if the length is valid for this card
        lengths = cards[cardType].length.split(",");
        for (j=0; j<lengths.length; j++) {
            if (cardNo.length == lengths[j]) LengthValid = true;
        }

        // See if all is OK by seeing if the length was valid. We only check the length if all else was
        // hunky dory.
        if (!LengthValid) {
            ccErrorNo = 4;
            return false;
        };
        //alert(cardType);
        // The credit card is in the required format.
        return cardname;
    }
})
